/* Hide the default radio button */
.custom-radio {
    display: none;
  }
  
  /* Create a square shape for the custom radio button */
  .custom-radio-label {
    position: relative;
    padding-left: 20px;
  }
  
  /* Create a square shape for the custom radio button */
  .custom-radio-label::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #DDDFE6;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .custom-radio-label-2::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #DDDFE6;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  /* Style the square when the radio button is checked */
  .custom-radio:checked + .custom-radio-label::before {
    background-color: #1A2956;
  }


/* 
  #activateComparisonLine{
    accent-color: #41CCAD; 
    color: white;
  }
   */


   
   /* Hide the default checkbox */
.custom-checkbox {
  display: none;
  font-weight: lighter;

}

/* Style the custom checkbox label */
.checkbox-label {
  position: relative;
  padding-left: 25px; /* Adjust as needed */
  cursor: pointer;
  font-weight: lighter;

}

/* Create a custom checkbox icon */
.checkbox-label::before {
  content: '';
  display: inline-block;
  width: 16px; /* Adjust the size of the checkbox */
  height: 15px; /* Adjust the size of the checkbox */
  border: 2px solid #DDDFE6; /* Set the border color (default is black) */
  background-color: #fff; /* Set the background color to white */
  border-radius: 4px; /* Make it slightly rounded */
  position: absolute;
  left: 0;
  font-weight: lighter;
  top: 3px;
}

/* Style the custom checkbox when it's checked */
.custom-checkbox:checked + .checkbox-label::before {
  background-color: #1A2956; 
  border: none;
  content: url("../image/tick.svg");
  /* text-align: center; */
  /* line-height: 11px; */
  /* color: white; */
  font-weight: lighter;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.thin-checkmark {
  font-weight: 100; /* Adjust the value as needed */
}
