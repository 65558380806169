@tailwind base;
@tailwind components;
@tailwind utilities;


.recharts-default-legend {
	display: none;
}

body {
	background-color: #FAFAF5 !important;
	overflow: hidden;
	font-family:'Inter',sans-serif !important
}

/* :root {

	scrollbar-color: #41ccad #ffffff;
	scrollbar-width: thin; 

	--scrollbar-thumb-color: #000000;
	--scrollbar-thumb-color: #DDDFE6;
	--scrollbar-thumb-hover-color: #1a2956;
	--scrollbar-thumb-hover-color: #41ccad;
	--scrollbar-track-color: #FFFFFF;
	--scrollbar-track-color: #FFFF00;
	--scrollbar-width: 6px;
	--scrollbar-height: 6px; 
	--scrollbar-track-border-color: #E9EAEF;
	 --scrollbar-thumb-border-radius: 4px;
} */

div {
	/* scrollbar-color: #DDDFE6 #ffffff;
	scrollbar-width: thin; */
}

/* ::-webkit-scrollbar {
	width: 5px;
	width: 6px;
	height:6px;
} */

/* Track */
::-webkit-scrollbar-track {
	border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 4px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background:#41ccad;
} */



/*Loading*/

/*Data Table*/
.gelpCx::-webkit-scrollbar {
	/* height: 5px;
	scrollbar-color: #41ccad #ffffff;
	scrollbar-width: thin; */
}

.rdt_TableCell::-webkit-scrollbar {
	/* width: 3px;
	scrollbar-color: #41ccad #ffffff;
	scrollbar-width: thin; */
}

.centerDiv::-webkit-scrollbar {
	/* width: 0px;
	scrollbar-color: #41ccad #ffffff;
	scrollbar-width: thin; */
}

.entitypopup::-webkit-scrollbar {
	display: none;
}

.hidescroll::-webkit-scrollbar {
	display: none;
}

::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #DDDFE6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #41ccad;
}

.category ::-webkit-scrollbar {
	display: none;
}

a {
	text-decoration: none !important;
	cursor: pointer !important;
}

input[type=checkbox] {
	/* accent-color: #41CCAD; */
	color: #ffffff;
	accent-color: #1a2956;
}

input[type=checkbox]:checked {
	color: white;
}

button {
	cursor: pointer !important;
}

::-moz-selection {
	/* Code for Firefox */
	/* color: #1a2956; */
	/* color: #ffffff;
	background: #41ccad; */
}

::selection {
	/* color: #1a2956; */
	/* color: #ffffff;
	background: #41ccad; */
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}



.lbFZWz {
	padding-top: 5px;
}

hr {
	margin-left: auto !important;
	margin-right: auto !important;
}

th {
	font-weight: 600 !important;
}


.d-block {
	display: block !important;
}

.d-none {
	display: none !important;
}

.custom-padding {
	padding: 95px 20px 0px 20px !important;
}

.filtration-box {
	height: auto !important;
	display: flex !important;
	justify-content: center;
	flex-wrap: wrap;
}

.db-label {
	color: #1a2956;
	text-transform: capitalize !important;
	font-size: 12px;
	font-weight: 600 !important;
	letter-spacing: 1px !important;
	font-family: Calibri;
}

.db-label-table {
	color: #1a2956;
	text-transform: capitalize !important;
	font-size: 14px;
	font-weight: 600 !important;
	letter-spacing: 1px !important;
	font-family: Calibri;
	display: flex !important;
	flex-wrap: wrap;
}

.exp-labels {
	border: 2px solid #efefef !important;
	border-radius: 5px;
	padding: 5px;
	margin-top: 5px;
}

.canvasjs-chart-toolbar {
	margin-top: -62px !important;
	background-color: white !important;
	z-index: 99;
}


.canvasjs-chart-toolbar button {
	background-color: white !important;
	border-radius: 5px !important;
}

.canvasjs-chart-toolbar button:hover {
	background-color: #41ccad !important;
	color: #41ccad !important;
}

.canvasjs-chart-toolbar div div:nth-child(1) {
	display: none
}

/*Input*/

.horizontal-column-chart {
	transform: rotateX(180deg);
}

.horizontal-column-chart svg {
	transform: rotate(90deg);
}

.chart-loading-box {
	height: 450px !important;
	position: relative;
}

.chart-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.chip {
	align-items: center;
	background: #41ccad !important;
	border-radius: 11px;
	color: #fff;
	display: inline-flex;
	font-size: 13px;
	line-height: 19px;
	margin-bottom: 5px;
	margin-right: 5px;
	padding: 4px 10px;
}

#search_input {
	box-shadow: none !important;
	border: 0px !important;
}

.plans {
	display: flex;
	justify-content: space-around;
}

.plan-box {
	width: 45%;
	text-align: center;
	padding: 10px;
	border: 2px solid #eaeaea;
	border-radius: 10px;
	color: #1a2956;
	margin-top: 5px;
	cursor: pointer;
	background-color: white
}

.active-price {
	font-size: 12px;
	font-weight: 600;
	color: #1a2956;
}

.inactive-price {
	font-size: 12px;
	font-weight: normal;
	color: gray;
}

.categories-list {
	border-radius: 5px !important;
	border: 2px solid #eaeaea !important;
	height: 100px !important;
}

.custom-input {
	width: 100% !important;
	border: 2px solid #efefef;
	border-radius: 5px !important;
	padding: 5px;
	margin-top: 5px;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	cursor: pointer;
}

.searchWrapper {
	border: 2px solid #efefef !important;
	border-radius: 5px !important;
	min-height: 22px !important;
	position: relative !important;
	font-size: 14px !important;
	margin-top: 5px !important;
	padding: 4px !important;
}

.searchBox:focus>.searchWrapper {
	border-color: #000 !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
.uneditable-input:focus {
	border-color: rgba(26, 41, 86, 1) !important;
	/* box-shadow: 0 0 0px 2px rgba(26, 41, 86, 0.1) inset !important;	 */
	/*border-color: rgba(65, 204, 173, 0.5) !important;*/
	/*box-shadow: 0 0 0px 2px rgba(65, 204, 173, 0.3) !important;*/
	outline: 0 none;
}

select {
	text-indent: 0px !important;
}


/*Subscription*/
.btn-box {
	height: auto;
	background-color: #efefef;
	padding: 10px;
	border-radius: 10px;
}

.btn-box .inactive {
	width: 50%;
	height: 50px;
	text-align: center;
	border-radius: 8px;
	border: 0px;
	/*transition: 0.3s all;*/
}

.btn-box .active {
	width: 50%;
	height: 50px;
	text-align: center;
	border-radius: 8px;
	border: 0px;
	transition: 0.3s all;
	color: white;
	font-weight: 600;
	background-color: #41ccad;
}

.card-temp {
	width: 280px;
	height: 160px;
	background: radial-gradient(circle, rgba(59, 74, 120, 1) 0%, rgba(26, 41, 86, 1) 100%);
	border-radius: 10px;
	box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
	margin: auto;
	padding: 10px;
	color: white;
	font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
	position: relative;
}

.card-temp:before {
	width: 50px;
	height: 40px;
	background-color: white;
	position: absolute;
	right: 10px;
	top: 10px;
	content: "";
	background-image: url("../image/gold-chip.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 10px;
}

.card-temp .name {
	width: 50%;
	color: white;
}

.card-temp .expiry {
	width: 50%;
	color: white;
}

#canvasjs-react-chart-container-1669372722779 {
	height: 100% !important;
}

@media print {
	@page {
		size: portrait !important;
	}

	nav {
		display: none !important;
	}

	#prt {
		background-color: white;
		height: 140% !important;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		margin: 0;
		padding: 15px;
		font-size: 14px;
		line-height: 18px;
	}
}

.sub-p p {
	font-size: 13px;
	font-weight: 600;
}

.sub-p p span {
	font-weight: 400 !important;
}

.subscription-box {
	padding: 30px 10px;
}

.subscription-box h4 {
	color: #1a2956;
	font-weight: 600;
}

.subscription-box p {
	color: #929292;
	font-weight: 300;
}

.subscription-box h1 {
	font-size: 35pt;
	color: #41ccad;
}

.subscription-box h1 span {
	font-size: 15pt;
	font-weight: 300;
	width: 60px;
	color: #929292;
}

.subscription-box button {
	width: 100%;
	height: 50px;
	background-color: #1a2956;
	color: white;
	border: 0px;
	border-radius: 10px;
	margin-top: 10px;
}

.subscription-box button:hover {
	background-color: #162b68;
}

/*Subscription*/

/*Input*/

#expense_input {
	width: 100px;
	margin-left: 40px;
	box-shadow: none !important;
	border: 0px !important;
}

.form-check-input:active {
	box-shadow: none !important;
}

.form-check-input:focus {
	box-shadow: none !important;
}

.form-check-input:checked {
	background-color: #41ccad !important;
	border-color: #41ccad !important;
	box-shadow: none !important;
}

/*#expense span.chip.singleChip.false {*/
/*    background-color: white !important;*/
/*    font-size: 16px !important;*/
/*    padding: 2px 0px 0px 5px !important*/
/*}*/
/*#expense .chip .icon_cancel {*/
/*    display: none !important*/
/*}*/

#supplier_input {
	width: 100px;
	margin-left: 40px;
	box-shadow: none !important;
	border: 0px !important;
}

.all-tag-exp,
.all-tag-sup {
	position: absolute;
	top: 45px;
	left: 17px;
	z-index: 99;
	background-color: #41ccad;
	color: white;
	padding: 2px 9px;
	border-radius: 99999px;
	font-size: 12px;
	display: block;
	cursor: pointer;
}

.all-tag-exp,
.all-tag-sup-theme2 {
	position: absolute;
	top: 37px;
	left: 10px;
	z-index: 99;
	background-color: #41ccad;
	color: white;
	padding: 2px 9px;
	border-radius: 99999px;
	font-size: 12px;
	display: block;
	cursor: pointer;
}

.company-select-div {
	/*width:"200px",height:"auto",position:"absolute",top:"-10px",right:"170px"*/
	width: 130px;
	height: auto;
	position: absolute;
	top: -8px;
	right: 120px;
}


.company-select {
	padding: 5px !important;
	font-size: 12px !important;
	text-transform: capitalize !important;
	font-weight: 600 !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

li.option:hover {
	background-color: #41ccad !important;
	color: white;
}


.gelpCx,
.gelpCx .ifOHjV {
	transform: rotateX(180deg);
}

.chart-box {
	/*height: 490px !important;*/
	height: 100% !important;
	/*padding: 20px 10px 0px 10px !important;*/
}

.filter-left {
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.filter-right {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.filter-center {
	border-radius: 0px !important;
}

.filter-btn {
	background-color: white !important;
	border: 2px solid #efefef;
	color: #929292;
	border-radius: 5px;
	height: 38px !important;
	width: 38px !important;
	transition: 0.3s all !important;
}

.filter-btn:hover {
	background-color: white !important;
	border: 2px solid #353535;
	color: #353535;
	border-radius: 5px;
	height: 38px !important;
	width: 38px !important;
	transition: 0.3s all !important;
}

.filter-active-btn {
	background-color: #41ccad !important;
	border: 2px solid #41ccad !important;
	color: white !important;
	border-radius: 5px;
	height: 38px !important;
	width: 38px !important;
	transition: 0.3s all !important;
}

.categories-badge-div {
	display: flex !important;
	flex-wrap: wrap;
	border: 2px solid #efefef;
	border-radius: 10px;
}

.username-badge {
	border: 2px solid white;
	border-radius: 20px;
	max-width: 90%;
	padding: 5px 8px 5px 8px;
	font-size: 12px;
	letter-spacing: 1px;
	font-weight: 600;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	cursor: pointer;
	text-transform: capitalize;
}

.badget {
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
	display: block;
	padding: 3px 5px !important;
	border-radius: 5px !important;
	font-size: 10px !important;
	color: #ffffff;
	/*Your desired text color*/
	font-weight: 600;
	/* background-color: #f44336; Your desired background color */

}

.badge-custom {
	background-color: #41ccad !important;
	border-radius: 3px !important;
	margin: 10px 5px 10px 5px !important;
	font-weight: 600 !important;
}

.badge-success {
	background-color: forestgreen !important;
}

.badge-danger {
	background-color: #e20202 !important;
}

.tailwind_btn {
	padding: 0.5rem;
	/* Assuming the base font size is 16px, 0.5rem is equivalent to 8px */
	border-radius: 0.25rem;
	/* Assuming the base font size is 16px, 0.25rem is equivalent to 4px */
	text-transform: uppercase;
}

/* Equivalent CSS for "success-btn" */
.vizii-green-btn {
	font-size: 0.65rem;
	/* Replace with the desired font size, or use "font-size: 10px;" as an approximation */
	font-weight: 600;
	/* "font-semibold" is typically equivalent to font-weight 600 */
	background-color: #41CCAD;
	/* Replace "yourColorCode" with the actual color code for vizii-green */
	color: #ffffff;
	/* Replace "yourColorCode" with the actual color code for vizii-white */
}

/* Equivalent CSS for "hover" state */
.vizii-green-btn:hover {
	/* background-color: #FF8800; Replace "yourColorCode" with the actual color code for success-dark */
	background-color: #1A2956;
}


/* Equivalent CSS for "success-btn" */
.success-btn {
	background-color: #00C851;
	/* Replace "yourColorCode" with the actual color code for vizii-success */
	color: #ffffff;
	/* Replace "yourColorCode" with the actual color code for vizii-white */
}

/* Equivalent CSS for "hover" state */
.success-btn:hover {
	background-color: #007E33;
	/* Replace "yourColorCode" with the actual color code for success-dark */
}

/* Equivalent CSS for "success-btn" */
.danger-btn {
	background-color: #ff4444;
	/* Replace "yourColorCode" with the actual color code for vizii-success */
	color: #ffffff;
	/* Replace "yourColorCode" with the actual color code for vizii-white */
}

/* Equivalent CSS for "hover" state */
.danger-btn:hover {
	background-color: #CC0000;
	/* Replace "yourColorCode" with the actual color code for success-dark */
}

/* Equivalent CSS for "success-btn" */
.warning-btn {
	background-color: #ffbb33;
	/* Replace "yourColorCode" with the actual color code for vizii-success */
	color: #1A2956;
	/* Replace "yourColorCode" with the actual color code for vizii-white */
}

/* Equivalent CSS for "hover" state */
.warning-btn:hover {
	background-color: #FF8800;
	/* Replace "yourColorCode" with the actual color code for success-dark */
}

/* Equivalent CSS for "success-btn" */
.gray-btn {
	background-color: #37474F;
	/* Replace "yourColorCode" with the actual color code for vizii-success */
	color: #ffffff;
	/* Replace "yourColorCode" with the actual color code for vizii-white */
}

/* Equivalent CSS for "hover" state */
.gray-btn:hover {
	background-color: #263238;
	/* Replace "yourColorCode" with the actual color code for success-dark */
}









.no-border tr td {
	border-bottom: 0px !important;
}

.no-border tr th {
	border-bottom: 0px !important;
}

.multiBackground {
	width: 100% !important;
	height: 100vh !important;
	background: linear-gradient(180deg, #efefef 50%, #1a2956 0) !important;
}

.app_text_color {
	color: #1a2956 !important;
}

/*Loading*/

.loading {
	position: fixed !important;
	width: 100% !important;
	height: 100% !important;
	top: 0 !important;
	left: 0 !important;
	text-align: center !important;
	background-color: white !important;
	z-index: 1200 !important;
	align-items: center !important;
	justify-content: center !important;
	transition: 0.3s all !important;
	cursor: progress !important;
}


.loading-image {
	pointer-events: none;
	position: absolute !important;
	top: 35% !important;
	left: auto !important;
	z-index: 100 !important;
}


.attachments {
	color: #41ccad !important;
	margin: 5px 5px;
	font-size: 15px;
}

/*.attachments {*/
/*    white-space: nowrap !important;*/
/*    width: 90px !important;*/
/*    overflow: hidden !important;*/
/*    text-overflow: ellipsis !important;*/
/*    border-radius: 5px !important;*/
/*    background-color: #41ccad !important;*/
/*    color: white !important;*/
/*    padding: 2px 4px 2px 4px !important;*/
/*    font-size: 10px !important;*/
/*    transition: 0.3s all !important;*/
/*    text-decoration: none !important;*/
/*    display: block !important;*/
/*    margin-top: 5px !important;*/
/*    margin-bottom: 5px !important;*/
/*}*/

/*.attachments:hover {*/
/*    background-color: #1a2956 !important;*/
/*    color: white !important;*/
/*    text-decoration: none !important;*/
/*    transition: 0.3s all !important;*/
/*}*/

.data-table-extensions {
	z-index: 9999 !important;
	width: 50% !important;
}

.data-table-extensions-action {
	display: none !important;
}

.export-csv-btn {
	background-color: #929292 !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
	color: white !important;
	font-weight: 600 !important;
	transition: 0.3s all !important;
	border-radius: 5px !important;
	border-color: #929292;
}

.export-csv-btn:hover {
	background-color: #656565 !important;
	transition: 0.3s all !important;
}

.export-csv-btn svg {
	margin-left: 5px !important;
}

.rdt_TableHeader {
	background-color: rgba(0, 0, 0, 0) !important;
	margin-top: -50px !important;
	width: 50% !important;
	float: right !important;
}

/*Data Table*/

.filter-text {
	font-size: 14px !important;
	margin-left: 10px !important;
	width: 50% !important;
	border: 2px solid #c9c9c9;
	border-radius: 5px !important;
	padding: 5px !important;
}


/*Confirm Box*/

.confirm-box {
	/* left: 40% !important;
	top: 40% !important; */
	z-index: 1000 !important;
	position: fixed !important;
	display: flex !important;
	width: 100vw !important;
	height: 100vh !important;
	left: 0 !important;
	top: 0 !important;
	align-items: center !important;
	justify-content: center !important;
}

.confirm-box__overlay {
	background-color: rgba(0, 0, 0, 0.3) !important;
	cursor: not-allowed !important;
}

.confirm-box__actions {
	border-top: 2px solid #efefef;
	margin-top: 15px;
}

.confirm-box__actions button:nth-child(1) {
	background-color: #41ccad !important;
	border: none !important;
	padding: 5px 20px 5px 20px;
	;
	border-radius: 5px !important;
	text-transform: capitalize;
	letter-spacing: 1px;
	font-size: 12px;
	font-weight: bold;
	color: white;
}

.confirm-box__actions button:nth-child(1):hover {
	background-color: #33af94 !important;
}

.confirm-box__actions button:nth-child(2) {
	background-color: #dc3545 !important;
	border: none !important;
	padding: 5px 20px 5px 20px;
	;
	border-radius: 5px !important;
	text-transform: capitalize;
	letter-spacing: 1px;
	font-size: 12px;
	font-weight: bold;
	color: white;
}

.confirm-box__actions button:nth-child(2):hover {
	background-color: #b83340 !important;
}

/*Confirm Box*/

.sign-in-btn {
	background-color: #142554 !important;
	font-weight: 600 !important;
	border-color: #142554 !important;
	color: white !important;
	transition: 0.3s all !important;
}

.sign-in-btn:hover {
	background-color: #1a2f68 !important;
	font-weight: 600 !important;
	border-color: #142554 !important;
	color: white !important;
	transition: 0.3s all !important;
}

.sign-in-btn:focus {
	background-color: #0c1b44 !important;
	font-weight: 600 !important;
	border-color: #142554 !important;
	color: white !important;
	transition: 0.3s all !important;
}

.btn-custom {
	background-color: #142554 !important;
	color: white !important;
	font-weight: 500 !important;
	border-color: #142554 !important;
}

.active {
	background-color: #41ccad !important;
	border-color: #142554 !important;
}

.btn-sync-custom {
	background-color: #41ccad !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
	color: white !important;
	font-weight: 600 !important;
	transition: 0.3s all !important;
	float: right !important;
	margin-top: 2px !important;
}

.btn-sync-custom:hover {
	background-color: #1a2956 !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
	color: white !important;
	font-weight: 600 !important;
	transition: 0.3s all !important;
	float: right !important
}

.btn-danger-custom {
	background-color: #dc3545 !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
	color: white !important;
	font-weight: 600 !important;
	transition: 0.3s all !important;
	float: right !important;
	margin-top: 2px !important;
}

.btn-danger-custom:hover {
	background-color: #bb2836 !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
	color: white !important;
	font-weight: 600 !important;
	transition: 0.3s all !important;
	float: right !important
}


.btn-sync-custom svg {
	margin-left: 5px !important;
}

/*.btn-sync-custom:hover svg {*/
/*    margin-left: 5px !important;*/
/*}*/

.p-10 {
	padding: 10px;
}

.cursor-pointer {
	cursor: pointer;
}

.mt-50 {
	margin-top: 10%;
}

.label {
	font-weight: bold;
	font-family: 'Arial Narrow';
}

hr {
	background-color: #c9c9c9 !important;
	border-color: #c9c9c9 !important;
	height: 2px !important;
}

.centerDiv {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 999 !important;
	background-color: white !important;
	border-radius: 10px !important;
	padding: 30px !important;
	box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.02);
	margin-left: 10px;
	margin-right: 10px;
}

.custom_btn {
	/*color: #1a2956;*/
	background: #41ccad !important;
	font-weight: 600 !important;
	border-color: #41ccad !important;
	color: white !important;
	transition: 0.3s all !important;
}

.custom_btn:hover {
	background: #33b69a !important;
	font-weight: 600 !important;
	border-color: #33b69a !important;
	color: white !important;
	transition: 0.3s all !important;
}

.user-table-btn {
	background-color: white;
	width: 45px;
	height: 45px;
	padding: 10px;
	text-align: center;
	line-height: 20px;
	margin-right: 5px;
	border-radius: 10px;
	border: 2px solid white;
	color: #1a2956;
	margin-top: 5px;
	margin-bottom: 5px;
	transition: 0.3s all;
	cursor: pointer;
}

.user-table-btn:hover {
	border: 2px solid #1a2956;
	box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3) inset;
	color: #1a2956;
	transition: 0.3s all;
}

.xeroBtn {
	color: rgb(34, 185, 235) !important;
	border: 2px solid rgb(34, 185, 235) !important;
	border-radius: 5px !important;
	padding: 10px !important;
	font-weight: 600 !important;
	position: relative;
	overflow: hidden;
}

.xeroBtn:hover:after {
	position: absolute;
	top: -2px;
	left: -20px;
	width: 0px !important;
	height: 105% !important;
	background-color: red !important;
	transform: rotate(20deg) !important;
	z-index: 999 !important;
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.03);
	content: "";
	animation-play-state: running;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-name: glass;
}

@keyframes glass {
	0% {
		left: -20px;
	}

	100% {
		left: 100%
	}
}


.qbBtn {
	color: rgb(5, 121, 198) !important;
	border: 2px solid rgb(5, 121, 198) !important;
	border-radius: 5px !important;
	padding: 10px !important;
	font-weight: 600 !important;
	position: relative;
	overflow: hidden;
}

.qbBtn:hover:after {
	position: absolute;
	top: -2px;
	left: -20px;
	width: 0px !important;
	height: 105% !important;
	background-color: red !important;
	transform: rotate(20deg) !important;
	z-index: 999 !important;
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.03);
	content: "";
	animation-play-state: running;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-name: glass;
}

.anchor {
	color: #33b69a !important;
	font-weight: 600 !important;
	cursor: pointer !important;
}

.error-text {
	color: #e80202 !important;
	font-size: 13px !important;
}

.nav-icon {
	padding: 5px;
	border-radius: 50% !important;
	transition: 0.3s all !important;
}

.nav-icon-active {
	background-color: #41ccad !important;
	padding: 5px;
	border-radius: 50% !important;
	transition: 0.3s all !important;
}

.nav-icon:hover {
	background-color: #41ccad !important;
	border-radius: 50% !important;
	transition: 0.3s all !important;
}


.side-nav {
	width: 260px !important;
	height: 100vh !important;
	position: fixed !important;
	/*right: -300px !important;*/
	top: 0 !important;
	z-index: 120 !important;
	overflow-y: auto;
	overflow-x: hidden;
	/*background-color: #1a2956 !important;*/
	background-image: linear-gradient(90deg, #1a2956, #142d79);
	padding-top: 100px;
	color: white;
	transition: 0.5s all !important;
	position: relative;
}


.side-nav .userInfo {
	text-align: center !important;
}

.side-nav .userInfo h6 {
	color: #41ccad !important;
	font-size: 13px;
	letter-spacing: 2px !important;
}

.side-nav .nav-items span {
	display: block;
	text-align: center !important;
	background-color: rgba(0, 0, 0, 0) !important;
	width: 100% !important;
	font-size: 15px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: 0.5s all !important;
	border-left: 0px solid #1a2956 !important;
	margin-top: 5px !important;
	text-transform: capitalize;
	font-family: "Calibri Light";
	position: relative !important;
}

.sideNav-title {
	display: block;
	text-align: center !important;
	background-color: rgba(0, 0, 0, 0.2) !important;
	width: 100% !important;
	font-size: 15px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: 0.5s all !important;
	border-left: 0px solid #1a2956 !important;
	margin-top: 5px !important;
	text-transform: capitalize;
	font-family: "Calibri Light";
	position: relative !important;
	margin-bottom: 0px;
}

.sideNav-title svg {
	position: absolute !important;
	top: 30% !important;
	left: 20px !important;
}

.side-nav .nav-items span svg {
	position: absolute !important;
	top: 30% !important;
	left: 20px !important;
}

.side-nav .nav-items span:hover {
	display: block;
	text-align: center !important;
	/*background-image: linear-gradient(90deg, #41ccad, #48e7c3);*/
	background-color: #41ccad !important;
	width: 100% !important;
	font-size: 15px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: 0.5s all !important;
	border-left: 10px solid #1a2956 !important;
	text-transform: capitalize;
	font-family: "Calibri Light";
}

.side-nav .nav-items .active {
	display: block;
	text-align: center !important;
	background-color: rgba(65, 204, 173, 0.4) !important;
	/*background-image: linear-gradient(90deg, #41ccad, #48e7c3);*/
	width: 100% !important;
	font-size: 15px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: 0.5s all !important;
	/*border-left: 10px solid #1a2956 !important;*/
	text-transform: capitalize;
	font-family: "Calibri";
}

.noHover:hover {
	display: block;
	text-align: center !important;
	/*background-image: linear-gradient(90deg, #41ccad, #48e7c3);*/
	background-color: transparent !important;
	width: 100% !important;
	font-size: 15px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: 0.5s all !important;
	border-left: 0px solid #1a2956 !important;
	text-transform: capitalize;
	font-family: "Calibri Light";
}

.side-nav .nav-items .active:hover {
	display: block;
	text-align: center !important;
	background-color: rgba(65, 204, 173, 0.4) !important;
	/*background-image: linear-gradient(90deg, #41ccad, #48e7c3);*/
	width: 100% !important;
	font-size: 15px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: 0.5s all !important;
	border-left: 0px solid #1a2956 !important;
	text-transform: capitalize;
	font-family: "Calibri";
}

.nav-sync-date {
	position: absolute;
	left: -100px;
	bottom: -23px;
	width: 250px;
	font-size: 11px;
	font-weight: 600;
}

.sync_at {
	color: #41ccad !important;
	font-size: 15px !important;
	/*position: absolute !important;*/
	bottom: 10px !important;
	margin-top: 20px;
	width: 100% !important;
	text-align: center !important;
	font-family: Calibri;
}

.sync_at:hover {
	color: whitesmoke !important;
	font-size: 15px !important;
	/*position: absolute !important;*/
	bottom: 10px !important;
	margin-top: 20px;
	width: 100% !important;
	text-align: center !important;
	font-family: Calibri;
}

/*Loading*/
.loading {
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
	background-color: white;
	z-index: 9999999;
}

.loading-image {
	position: absolute;
	/*background-color: rgba(255,255,255,0.8);*/
	top: 48%;
	left: 46%;
	z-index: 100;
}

.loading-image-sync {
	position: absolute;
	/*background-color: rgba(255,255,255,0.8);*/
	top: 40%;
	left: 43%;
	z-index: 100;
}

/*Loading*/


.firstFalse:before {
	list-style-type: none;
	content: '⚬ ';
}

.firstTrue:before {
	list-style-type: none;
	content: '✓ ';
	color: white;
}


.c-margin {
	margin-right: 20px
}

.company-select-div-side-nav {
	display: none;
}

.attachment-download {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 999;
	border-radius: 5px;
	color: #41ccad !important;
	border: 2px solid #41ccad;
	background-color: white;
	text-decoration: none;
	padding: 0px 5px 0px 5px;
}

.appStatus {
	width: auto;
	padding: 1px 20px;
	font-weight: 600;
	text-transform: capitalize;
	font-size: 11px;
	position: fixed;
	top: 84px;
	color: white;
	left: 50%;
	z-index: 999;
	transform: translateX(-50%);
	letter-spacing: 1px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	cursor: pointer;
}

/*Confiramtion box*/
.confirm-box__actions button:nth-child(2) {
	background-color: #dc3545 !important;
	border: none !important;
	padding: 5px 20px 5px 20px;
	border-radius: 5px !important;
	text-transform: capitalize;
	letter-spacing: 1px;
	font-size: 12px;
	font-weight: bold;
	float: right !important;
	margin-right: 10px;
	color: white;
}

.confirm-box__actions button:nth-child(1) {
	background-color: #41ccad !important;
	border: none !important;
	padding: 5px 20px 5px 20px;
	border-radius: 5px !important;
	text-transform: capitalize;
	letter-spacing: 1px;
	font-size: 12px;
	font-weight: bold;
	color: white;
	margin-right: 0px;
	float: right !important;
}

.confirm-box__actions {
	display: inline-block !important;
	padding-top: 1em;
	justify-content: flex-end;
	width: 100% !important;
}

.confirm-box__actions {
	border-top: 2px solid #efefef;
	margin-top: 15px;
	/*margin-bottom: 30px;*/
}

.ReactModal__Overlay {
	z-index: 9999;
}

/*Month Picker*/

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
	box-sizing: border-box;
	width: 100%;
	z-index: 100;
}

.react-date-picker__clear-button {
	display: none
}

.react-date-picker__calendar-button {
	width: 50px !important;
}

.react-date-picker__calendar-button svg {
	width: 30px !important;
}

.react-date-picker__wrapper {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	border: 2px solid #efefef !important;
	border-radius: 5px;
	padding: 3px;
	margin-top: 5px;
	width: 100%;
}

button.react-calendar__navigation__label {
	width: 460px !important;
}

rect {
	width: 18px !important;
}

/*line {*/
/*    display: none !important;*/
/*}*/


/*select {-webkit-appearance:none;*/
/*-webkit-background-image: }*/
@media only screen and (max-width: 1200px) {
	.sub-div {
		padding: 150px 10px 20px 10px !important
	}
}

@media only screen and (max-width: 1100px) {
	.sub-div {
		padding: 140px 10px 20px 10px !important
	}
}

@media only screen and (max-width: 900px) {

	.nav-sync-date {
		position: absolute;
		left: -100px;
		bottom: -23px;
		width: 300px;
		font-size: 11px;
		font-weight: 600;
	}

	.canvasjs-chart-toolbar {
		right: -29px !important;
		top: -14px !important;
		background-color: white !important;
		z-index: 99;
	}

	.filter-text {
		font-size: 14px !important;
		margin-let: 10px !important;
		width: 100% !important;
		border: 2px solid #c9c9c9;
		border-radius: 5px !important;
		padding: 5px !important;
	}

	.c-margin {
		margin-right: 10px
	}

	.company-select-div {
		/*width:"200px",height:"auto",position:"absolute",top:"-10px",right:"170px"*/
		display: none;
	}

	.company-select-div-side-nav {
		display: block;
		padding: 0px 20px 0px 20px;
	}

	.sync_at {
		/*color: whitesmoke !important;*/
		font-size: 15px !important;
		margin-top: 20px;
		/*position: absolute !important;*/
		bottom: 10px !important;
		font-family: Calibri;
		width: 100%;
		text-align: center;
	}

}


.optionListContainer {
	height: fit-content;
	overflow-y: auto;
}

.search-wrapper {
	max-height: 6rem;
	overflow-y: auto;
}

.badget {
	height: fit-content;
}


/* DATE PICKER STYLING FOR CUSTOM */
#custom .react-datepicker {
	border: none !important;
	background-color: white !important;
}

#custom .react-datepicker__header {
	background-color: #ffff !important;
	border-bottom: none !important;
}

#custom .react-datepicker__triangle {
	display: none;
}


#custom .react-datepicker__month-container {
	background-color: #ffff;
	border-radius: 1rem;
	box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.15);
}

#custom .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	color: #111928 !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#custom .react-datepicker__day,
.react-datepicker__time-name {
	cursor: default;
	/* color: #ffff !important; */
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#custom .react-datepicker__day,
.react-datepicker__time-name {
	cursor: default;
	/* color: #ffff !important; */
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#custom .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	color: #ffffff !important;
}

#custom .react-datepicker__day-name {
	color: #9CA3AF !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#custom .react-datepicker__day--selected {
	color: #ffff !important;
	background-color: #1a2956 !important;
	border-radius: 4px !important;
}

#custom .react-datepicker__current-month {
	color: #1a2956 !important;
	font-family: "Inter", sans-serif !important;
}

#custom .react-datepicker__navigation--previous {
	left: 2px !important;
	top: 6px !important;
	color: #00C851 !important;
}

#custom .react-datepicker__navigation--next {
	right: 2px;
	top: 6px !important;
}

#custom .react-datepicker__month {
	margin: 0.4rem;
	text-align: center !important;
}



/* DATE PICKER STYLING FOR MNTH  */
#month .react-datepicker {
	border: none !important;
	background-color: white !important;
}

#month .react-datepicker__header {
	background-color: #ffff !important;
	border-bottom: none !important;
}

#month .react-datepicker__triangle {
	display: none;
}


#month .react-datepicker__month-container {
	background-color: #ffff;
	border-radius: 1rem;
	box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.15);
}

#month .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	color: #111928 !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#month .react-datepicker__day,
.react-datepicker__time-name {
	cursor: default;
	color: #1a2956 !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#month .react-datepicker__day-name {
	color: #9CA3AF !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#month .react-datepicker__day--selected {
	color: #ffff !important;
	background-color: #1a2956 !important;
	border-radius: 4px !important;
}

#month .react-datepicker__current-month {
	color: #1a2956 !important;
	font-family: "Inter", sans-serif !important;
}

#month .react-datepicker__navigation--previous {
	left: 2px !important;
	top: 6px !important;
	color: #00C851 !important;
}

#month .react-datepicker__navigation--next {
	right: 2px;
	top: 6px !important;
}

#month .react-datepicker__month {
	/* margin: 0.4rem; 
    text-align: center; */
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 10rem; */
	flex-wrap: wrap
}

#month .react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
	display: inline-block;
	width: 2rem;
	margin: 8px;
	text-align: center;
}

/* TABLE CUSTOM STYLES  */
.rdt_Table {
	font-family: 'Inter';
}

.kUMlDB {
	color: #111928;
}

.fxvrKk {
	display: flex;
	width: 100%;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 1;
	color: rgba(0, 0, 0, 0.87);
	font-size: 12px;
	font-weight: 500;
	height: 40px;
	margin-bottom: 0.7rem;
}

.kUMlDB {
	cursor: pointer;
}

.kUMlDB:hover {
	background-color: #F7F4FE;
}

.lnOUep {
	overflow: auto;
}

.mHisf {
	display: flex;
	align-items: stretch;
	width: 100%;
	background-color: #FFFFFF;
	min-height: 40px;
	border-bottom-width: 1px;
	border-bottom-color: rgba(0, 0, 0, .12);
	border-bottom-style: solid;
}

.hkqAS,
.sc-kFCroH,
.bnCaEe {
	opacity: 1 !important;
	color: black !important;
}

.dropdown-menu {
	max-height: 0;
	overflow: hidden;
}

.dropdown-menu.close {
	transition: max-height 0.2s ease-out;
	max-height: 0px;
}

.dropdown-menu.open {
	transition: max-height 0.7s ease-in-out;
	max-height: 1000px;
}


/* ANMATION  */

.blinkingBox {
	background-color: #ffffff;
	animation: blink 1s ease-in-out infinite;
}

@keyframes blink {

	0%,
	100% {
		background-color: #ffffff;
	}

	50% {
		background-color: #F7F4FE;
	}
}



/* DATE PICKER STYLING FOR CUSTOM */
#Responsivecustom .react-datepicker {
    border: none !important;
    background-color: white !important;
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: relative;
    right: 40%;
    left: 40%;
    transform: translateX(-40%,-40%);
	bottom: 4rem;
}

#Responsivecustom .react-datepicker__header {
	background-color: #ffff !important;
	border-bottom: none !important;
}

#Responsivecustom .react-datepicker__triangle {
	display: none;
}


#Responsivecustom .react-datepicker__month-container {
	background-color: #ffff;
	border-radius: 1rem;
	box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.15);
}

#Responsivecustom .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	color: #111928 !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#Responsivecustom .react-datepicker__day,
.react-datepicker__time-name {
	cursor: default;
	/* color: #ffff !important; */
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#Responsivecustom .react-datepicker__day,
.react-datepicker__time-name {
	cursor: default;
	/* color: #ffff !important; */
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#Responsivecustom .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	color: #ffffff !important;
}

#Responsivecustom .react-datepicker__day-name {
	color: #9CA3AF !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#Responsivecustom .react-datepicker__day--selected {
	color: #ffff !important;
	background-color: #1a2956 !important;
	border-radius: 4px !important;
}

#Responsivecustom .react-datepicker__current-month {
	color: #1a2956 !important;
	font-family: "Inter", sans-serif !important;
}

#Responsivecustom .react-datepicker__navigation--previous {
	left: 2px !important;
	top: 6px !important;
	color: #00C851 !important;
}

#Responsivecustom .react-datepicker__navigation--next {
	right: 2px;
	top: 6px !important;
}

#Responsivecustom .react-datepicker__month {
	margin: 0.4rem;
	text-align: center !important;
}



/* DATE PICKER STYLING FOR MNTH  */
#Responsivemonth .react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none !important;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    width: 15rem !important;
	position: relative;
    right: 40%;
    left: 40%;
    transform: translateX(-40%,-40%);
	bottom: 4rem;
}

#Responsivemonth .react-datepicker__header {
	background-color: #ffff !important;
	border-bottom: none !important;
}

#Responsivemonth .react-datepicker__triangle {
	display: none;
}


#Responsivemonth .react-datepicker__month-container {
	background-color: #ffff;
	border-radius: 1rem;
	box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.15);
}

#Responsivemonth .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	color: #111928 !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#Responsivemonth .react-datepicker__day,
.react-datepicker__time-name {
	cursor: default;
	color: #1a2956 !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#Responsivemonth .react-datepicker__day-name {
	color: #9CA3AF !important;
	font-family: "Inter", sans-serif !important;
	font-weight: bolder !important;
}

#Responsivemonth .react-datepicker__day--selected {
	color: #ffff !important;
	background-color: #1a2956 !important;
	border-radius: 4px !important;
	
}
#Responsivemonth .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #1a2956 !important;
    color: #ffff !important;
}

#Responsivemonth .react-datepicker__current-month {
	color: #1a2956 !important;
	font-family: "Inter", sans-serif !important;
}

#Responsivemonth .react-datepicker__navigation--previous {
	left: 2px !important;
	top: 6px !important;
	color: #00C851 !important;
}

#Responsivemonth .react-datepicker__navigation--next {
	right: 2px;
	top: 6px !important;
}

#Responsivemonth .react-datepicker__month {
	/* margin: 0.4rem; 
    text-align: center; */
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 10rem; */
	flex-wrap: wrap
}

#Responsivemonth .react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
	display: inline-block;
	width: 2rem;
	margin: 8px;
	text-align: center;
}



@keyframes slideInUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideOutDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

.animate-slideInUp {
    animation: slideInUp 0.5s forwards;
}

.animate-slideOutDown {
    animation: slideOutDown 0.5s forwards;
}